import Link from 'next/link';
import dynamic from 'next/dynamic';

const VideoSchema = dynamic(() => import('./HomePageVideoSchema'));

interface HomepageVideoProps {
  desktopVideoUrl: string;
  mobileVideoUrl: string;
  videoAltText: string;
}

function HomepageVideo({ desktopVideoUrl, mobileVideoUrl, videoAltText }: HomepageVideoProps) {
  const desktopThumbnailUrl = `${desktopVideoUrl.replace('https://cdn.dresden.vision/video/home-video', 'https://w.dresden.vision/img/video/home-video')}.webp?q=60`;
  const mobileThumbnailUrl = `${mobileVideoUrl.replace('https://cdn.dresden.vision/video/home-video', 'https://w.dresden.vision/img/video/home-video')}.webp?q=40`; 

  return (
    <Link href="/shop" className="block cursor-pointer">
      <VideoSchema
            videoAltText={videoAltText}
            desktopThumbnailUrl={desktopThumbnailUrl}
            mobileThumbnailUrl={mobileThumbnailUrl}
            desktopVideoUrl={desktopVideoUrl}
          />
      <div 
        className="relative w-full pt-[124.80%]  md:hidden" // 16:9 aspect ratio
      >
        <video
          playsInline
          autoPlay
          muted 
          loop
          poster={mobileThumbnailUrl}
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src={mobileVideoUrl} type="video/mp4" />
          <meta itemProp="description" content={videoAltText} />
        </video></div>
        <div 
        className="relative w-full pt-[40.00%]  hidden md:block" // 16:9 aspect ratio
      >
        <video
          playsInline
          autoPlay
          muted
          loop
          poster={desktopThumbnailUrl}
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src={desktopVideoUrl} type="video/mp4" /> 
          <meta itemProp="description" content={videoAltText} />
      
        </video>
      </div>
    </Link>
  );
}

export default HomepageVideo;